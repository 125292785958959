.education {
  background: linear-gradient(to right, #f8f8f8, #fcfcfc, #f8f8f8);
  text-align: center;
  font-family: "Roboto", sans-serif;
  padding-top: 50px;
  h1 {
    padding: 25px;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 50px;
  }
}
.education-container {
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.part-one,
.part-two,
.part-tree,
.part-four {
  width: 30%;
  height: 300px;
  border-top: 1px solid #25ccf7;
  margin-top: 20px;
  line-height: 25px;
}

.circle-date-one,
.circle-date-two,
.circle-date-tree,
.circle-date-four,
.circle-date-five {
  margin: -280px 5px 0px 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #25ccf7;
}

.circle-date-one::before,
.circle-date-two::before,
.circle-date-tree::before,
.circle-date-four::before,
.circle-date-five::before {
  position: absolute;
  margin-top: -30px;
  margin-left: -19px;
  font-weight: 700;
  letter-spacing: 1px;
}

.circle-date-one::before {
  content: "2005";
}
.circle-date-two::before {
  content: "2011";
}
.circle-date-tree::before {
  margin-left: -48px;
  content: "2013 - 2019";
}
.circle-date-four::before {
  margin-left: -48px;
  content: "2013 - 2019";
}
.circle-date-five::before {
  content: "2020";
}

.cap {
  font-size: 30px;
  margin-top: 15px;
}
