.header {
  position: sticky;
  height: 80px;
  background-color: #ffffff;
  display: flex;
}
.header-logo {
  width: 30%;
  height: 80px;
  display: flex;
  align-items: center;
  i:nth-child(1) {
    margin-left: 70px;
    margin-top: 15px;
    font-size: 18px;
  }
  i:nth-child(2) {
    font-size: 33px;
    color: #00bbe6;
  }
  i:nth-child(3) {
    margin-top: 15px;
    font-size: 18px;
  }
  strong: {
    font-family: "Roboto", sans-serif;
    color: #23c6ec;
    font-size: 35px;
    padding: 0px 0px 0px 5px;
  }
}
.header-navbar {
  height: 80px;
  width: 75%;
  padding-right: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: "Roboto", sans-serif;
  font-size: 0.85rem;
  font-weight: bold;
  color: #8a8a8a;
  text-align: left;
  p {
    padding: 5px 30px 5px 0px;
    transition: 500ms;
  }
  hr {
    position: absolute;
    width: 0px;
    margin-top: 22px;
    margin-right: 0px;
    background-color: #ffffff;
    border: 1px solid #ffffff;
    transition: 500ms;
  }
  :hover > hr {
    background-color: #1cc1e8;
    border: 1px solid #1cc1e8;
    width: 20px;
  }
  :hover {
    cursor: pointer;
    color: black;
  }
  i {
    color: #1cc1e8;
  }
  i:hover {
    color: #1cc1e8;
    cursor: default;
  }
}
.header-nav-selected {
  hr {
    background-color: #1cc1e8;
    border: 1px solid #1cc1e8;
    width: 20px;
  }
  p {
    color: black;
  }
}
