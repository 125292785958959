@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;700&display=swap);
* {
  margin: 0px;
  padding: 0px;
}
@-webkit-keyframes FadeIn{
  from{
      opacity: 0;
  }
  to{
      opacity: 1
  }
}
@keyframes FadeIn{
  from{
      opacity: 0;
  }
  to{
      opacity: 1
  }
}
body{
  -webkit-animation: FadeIn 2500ms;
          animation: FadeIn 2500ms;
}
::selection {
  color: white;
  border-radius: 5px;
  background-color: #23c6ec;
}
::-webkit-scrollbar {
  width: 0px;
}

.header{position:-webkit-sticky;position:sticky;height:80px;background-color:#ffffff;display:flex}.header-logo{width:30%;height:80px;display:flex;align-items:center;strong-font-family:"Roboto", sans-serif;strong-color:#23c6ec;strong-font-size:35px;strong-padding:0px 0px 0px 5px}.header-logo i:nth-child(1){margin-left:70px;margin-top:15px;font-size:18px}.header-logo i:nth-child(2){font-size:33px;color:#00bbe6}.header-logo i:nth-child(3){margin-top:15px;font-size:18px}.header-navbar{height:80px;width:75%;padding-right:80px;display:flex;align-items:center;justify-content:flex-end;font-family:"Roboto", sans-serif;font-size:0.85rem;font-weight:bold;color:#8a8a8a;text-align:left}.header-navbar p{padding:5px 30px 5px 0px;transition:500ms}.header-navbar hr{position:absolute;width:0px;margin-top:22px;margin-right:0px;background-color:#ffffff;border:1px solid #ffffff;transition:500ms}.header-navbar :hover>hr{background-color:#1cc1e8;border:1px solid #1cc1e8;width:20px}.header-navbar :hover{cursor:pointer;color:black}.header-navbar i{color:#1cc1e8}.header-navbar i:hover{color:#1cc1e8;cursor:default}.header-nav-selected hr{background-color:#1cc1e8;border:1px solid #1cc1e8;width:20px}.header-nav-selected p{color:black}

.left-navbar{position:-webkit-sticky;position:sticky;height:100%;width:80px;background-color:#ffffff;display:flex;align-items:center}.left-nav-container{display:flex;align-items:center;justify-content:space-around;flex-wrap:wrap;height:50%}.nav-items{width:40px;height:40px;display:flex;align-items:center;justify-content:space-around}.circle{width:8px;height:8px;border-radius:50%;border:5px solid #e6e6e6;transition:300ms}.circle:hover{border:2px solid #23c6ec;width:17px;height:17px;cursor:pointer}.left-nav-selected{border:2px solid #23c6ec;width:17px;height:17px}.left-nav-selected :hover{cursor:default}.tooltip{position:absolute;margin-left:105px;padding:10px;background-color:tomato;z-index:100}

.intro{background:linear-gradient(to right, #f8f8f8, #fcfcfc, #f8f8f8);height:700px;display:flex}.intro h1{padding:25px}.introduce{width:50%;height:100%;display:flex;justify-content:flex-end;align-items:center;font-family:"Roboto", sans-serif}.introduce div{width:70%}.introduce div p:nth-child(1){font-size:35px;font-weight:700;color:#222f3e}.introduce div p:nth-child(2){font-size:60px;font-weight:700;margin-top:15px;color:#222f3e}.introduce div p:nth-child(3){font-size:25px;font-weight:100;margin-top:20px;color:#808080}.introduce div p:nth-child(4){font-size:18px;font-weight:100;margin-top:7px;color:#808080}.introduce div p:nth-child(5){font-size:16px;font-weight:100;margin-top:7px;color:#808080}.introduce div .link-portfolio p{font-size:17px;font-weight:100;margin-top:100px;color:#808080;transition:200ms}.introduce div .link-portfolio p i{margin:0px 0px 0px 5px}.introduce div .link-portfolio p:hover{color:#00bbe6;cursor:pointer}.photo-container{width:50%;height:100%;display:flex;justify-content:flex-start;align-items:center;font-family:"Roboto", sans-serif}.photo-container div{margin-left:15%}.photo-container div img{width:350px;height:350px;border-radius:50%}

.about-container{background-image:url("/12.jpg");height:500px;font-family:"Roboto", sans-serif;display:flex;align-items:flex-end;color:white}.about-container h1{padding:25px}.about-left-side{width:30%;height:74%;display:flex;align-items:flex-start;justify-content:center;font-size:22px;font-weight:700}.about-right-side{width:60%;height:74%;font-size:17px;font-weight:100}.about-right-side p{line-height:30px}

.skills-container{font-family:"Roboto", sans-serif;background-repeat:no-repeat;background-size:contain}.skills-container h1{padding:25px}.skills-title-1{width:30%;height:90%;font-family:"Roboto", sans-serif;text-align:center;font-size:22px;padding-top:95px;font-weight:700}.skills-title-2{width:30%;height:90%;font-family:"Roboto", sans-serif;text-align:center;font-size:22px;padding-top:45px;font-weight:700}.technical-container{width:100%;display:flex;font-family:"Roboto", sans-serif;text-align:center;font-size:22px;font-weight:700}.technical{width:70%;height:580px;padding-top:100px;margin-bottom:10px;background-color:white;display:grid;grid-template-columns:auto auto auto auto}.technical a{width:150px;height:150px;font-size:5rem;display:flex;justify-content:center;align-items:center;border-radius:3px;background-color:#f9f9f9;transition:200ms}.technical a:hover{transform:scale(1.08);cursor:pointer}.technical a:nth-child(1){color:#25ccf7;text-decoration:none}.technical a:nth-child(2) img{width:110px;height:100px}.technical a:nth-child(3) img{width:110px;height:100px}.technical a:nth-child(4) img{width:90px;height:110px}.technical a:nth-child(5){color:#f7b731;text-decoration:none}.technical a:nth-child(6){color:#ee5a24;text-decoration:none}.technical a:nth-child(7){color:#1e90ff;text-decoration:none}.technical a:nth-child(8){color:#e84393;text-decoration:none}.non-technical{height:780px;background-image:url("/team-work.jpg");background-size:cover;color:white}.filter{background-color:rgba(0,0,0,0.5);width:100%;height:100%;display:flex}.non-technical-skill{width:60%;color:white;font-family:"Roboto", sans-serif;font-weight:100;padding-top:50px;line-height:22px}.non-technical-skill p{margin-left:110px}

.education{background:linear-gradient(to right, #f8f8f8, #fcfcfc, #f8f8f8);text-align:center;font-family:"Roboto", sans-serif;padding-top:50px}.education h1{padding:25px;font-size:22px;font-weight:700;letter-spacing:1px;margin-bottom:50px}.education-container{font-size:16px;display:flex;justify-content:center;align-items:center}.part-one,.part-two,.part-tree,.part-four{width:30%;height:300px;border-top:1px solid #25ccf7;margin-top:20px;line-height:25px}.circle-date-one,.circle-date-two,.circle-date-tree,.circle-date-four,.circle-date-five{margin:-280px 5px 0px 5px;width:20px;height:20px;border-radius:50%;border:2px solid #25ccf7}.circle-date-one::before,.circle-date-two::before,.circle-date-tree::before,.circle-date-four::before,.circle-date-five::before{position:absolute;margin-top:-30px;margin-left:-19px;font-weight:700;letter-spacing:1px}.circle-date-one::before{content:"2005"}.circle-date-two::before{content:"2011"}.circle-date-tree::before{margin-left:-48px;content:"2013 - 2019"}.circle-date-four::before{margin-left:-48px;content:"2013 - 2019"}.circle-date-five::before{content:"2020"}.cap{font-size:30px;margin-top:15px}

.experience{background:linear-gradient(to right, #136a8a, #267871);text-align:center;font-family:"Roboto", sans-serif}.experience h1{padding:75px;font-size:25px;color:white}.experience-container{font-size:16px;display:flex;justify-content:center;align-items:center;color:white}.ex-part-one,.ex-part-two,.ex-part-tree,.ex-part-four{width:25%;height:300px;border-top:1px solid #25ccf7;margin-top:20px;line-height:25px}.ex-circle-date-one,.ex-circle-date-two,.ex-circle-date-tree,.ex-circle-date-four,.ex-circle-date-five{margin:-280px 5px 0px 5px;width:20px;height:20px;border-radius:50%;border:2px solid #25ccf7}.ex-circle-date-one::before,.ex-circle-date-two::before,.ex-circle-date-tree::before,.ex-circle-date-four::before,.ex-circle-date-five::before{position:absolute;margin-top:-30px;margin-left:-19px;font-weight:700;letter-spacing:1px}.ex-circle-date-one::before{content:"2013"}.ex-circle-date-two::before{content:"2015"}.ex-circle-date-tree::before{margin-left:-48px;content:"2017 - 2019"}.ex-circle-date-four::before{content:"2020"}.ex-cap{font-size:30px;margin-top:15px}

.projects{background:linear-gradient(to right, #f8f8f8, #fcfcfc, #f8f8f8);height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;padding-bottom:40px;font-family:"Roboto", sans-serif;text-align:center}.projects h1{padding:50px;font-size:28px}.project-container{text-align:center;display:grid;grid-template-columns:auto auto auto auto;grid-row-gap:50px;row-gap:50px;grid-column-gap:50px;-webkit-column-gap:50px;column-gap:50px;align-content:space-around;justify-content:center}.project-container .project-card{width:220px;height:150px;background-color:#f0f0f0;padding:10px;border-radius:4px;transition:200ms}.project-container .project-card:hover{transform:scale(1.05)}.project-container .project-card:hover>.hover-link{display:block}.project-container img{width:220px;height:150px;border-radius:4px;z-index:1}.hover-link{position:absolute;display:none;margin:-1px 0px 0px -1px;padding:5px;width:220px;height:150px;background-color:#f0f0f0;transition:200ms}.hover-link div:nth-child(1){display:flex;justify-content:center;align-items:center;width:100%;height:50%;font-weight:700}.hover-link div:nth-child(2){height:50%;display:flex;justify-content:center;align-items:center;width:100%}.hover-link div:nth-child(2) a{text-decoration:none}.hover-link div:nth-child(2) i{width:70px;height:40px;margin:5px;font-size:20px;display:flex;align-items:center;justify-content:center;transition:200ms;color:#1b9cfc}.hover-link div:nth-child(2) i:hover{transform:scale(1.2);cursor:pointer}

.contact{background-image:url("/email-2.jpg");height:80vh;font-family:"Roboto", sans-serif;background-size:cover;color:white}.contact h1{padding:25px}.contact-form{width:100%;height:100%;background-color:rgba(0,0,0,0.4);display:flex}.contact-form div:nth-child(1){width:30%;height:70%;margin-top:100px;display:flex;justify-content:center;font-size:14px;font-weight:700}.contact-form div:nth-child(2){width:60%;height:70%;margin-top:110px;font-size:16px;font-weight:700;display:flex;flex-wrap:wrap;justify-content:center}.contact-form div:nth-child(2) input{width:40%;margin:5px;height:50px;border:none;text-indent:20px;background-color:rgba(0,0,0,0);border-bottom:1px solid white;color:white;font-size:16px;outline:none}.contact-form div:nth-child(2) input[type="submit"]{background-color:#1B9CFC;border:none;transition:200ms;border-radius:3px}.contact-form div:nth-child(2) input[type="submit"]:hover{background-color:#46afff;border:none;cursor:pointer}.contact-form div:nth-child(2) textarea{padding-left:20px;font-size:16px;width:80%;height:200px;background-color:rgba(0,0,0,0);border:none;color:white;border-bottom:1px solid white;resize:none;outline:none}::-webkit-input-placeholder{color:whitesmoke}:-ms-input-placeholder{color:whitesmoke}::-ms-input-placeholder{color:whitesmoke}::placeholder{color:whitesmoke}

.body{position:-webkit-sticky;position:sticky;height:100%;width:100%;overflow:auto;z-index:0}

.right-pannel{position:-webkit-sticky;position:sticky;height:100%;width:80px;background-color:#ffffff}

.footer{position:-webkit-sticky;position:sticky;height:80px;background-color:#ffffff;display:flex;font-size:18px;align-items:center;justify-content:space-between;font-family:"Roboto", sans-serif}.footer div:first-child{color:#8a8a8a;margin-left:80px;font-size:12px}.links-container{height:80px;display:flex;font-size:18px;justify-content:flex-end;align-items:center}.links-container a:nth-child(1){color:#1B9CFC}.links-container a:nth-child(2){color:#596275}.links-container a:nth-child(3){color:#25CCF7}.links-container a:nth-child(4){color:#EE5A24;margin-right:80px}.links-container i{padding:0px 15px;transition:300ms}.links-container i:hover{transform:scale(1.2);cursor:pointer}

.App{
    height: 100vh;
}
.main-container{
    height: calc(100vh - 160px);
    display: flex;
}
