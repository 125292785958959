.projects {
  background: linear-gradient(to right, #f8f8f8, #fcfcfc, #f8f8f8);
  height: fit-content;
  padding-bottom: 40px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  h1 {
    padding: 50px;
    font-size: 28px;
  }
}

.project-container {
  text-align: center;
  display: grid;
  grid-template-columns: auto auto auto auto;
  row-gap: 50px;
  column-gap: 50px;
  align-content: space-around;
  justify-content: center;
  .project-card {
    width: 220px;
    height: 150px;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 4px;
    transition: 200ms;
  }
  .project-card:hover {
    transform: scale(1.05);
  }
  .project-card:hover > .hover-link {
    display: block;
  }
  img {
    width: 220px;
    height: 150px;
    border-radius: 4px;
    z-index: 1;
  }
}

.hover-link {
  position: absolute;
  display: none;
  margin: -1px 0px 0px -1px;
  padding: 5px;
  width: 220px;
  height: 150px;
  background-color: #f0f0f0;
  transition: 200ms;
  div:nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50%;
    font-weight: 700;
  }
  div:nth-child(2) {
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    a {
      text-decoration: none;
    }
    i {
      width: 70px;
      height: 40px;
      margin: 5px;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 200ms;
      color: #1b9cfc;
    }
    i:hover {
      transform: scale(1.2);
      cursor: pointer;
    }
  }
}
