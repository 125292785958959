@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;700&display=swap");
.intro {
  background: linear-gradient(to right, #f8f8f8, #fcfcfc, #f8f8f8);
  height: 700px;
  display: flex;
  h1 {
    padding: 25px;
  }
}
.introduce {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: "Roboto", sans-serif;
  div {
    width: 70%;
    p:nth-child(1) {
      font-size: 35px;
      font-weight: 700;
      color: #222f3e;
    }
    p:nth-child(2) {
      font-size: 60px;
      font-weight: 700;
      margin-top: 15px;
      color: #222f3e;
    }
    p:nth-child(3) {
      font-size: 25px;
      font-weight: 100;
      margin-top: 20px;
      color: #808080;
    }
    p:nth-child(4) {
      font-size: 18px;
      font-weight: 100;
      margin-top: 7px;
      color: #808080;
    }
    p:nth-child(5) {
      font-size: 16px;
      font-weight: 100;
      margin-top: 7px;
      color: #808080;
    }
    .link-portfolio {
      p {
        font-size: 17px;
        font-weight: 100;
        margin-top: 100px;
        color: #808080;
        transition: 200ms;
        i {
          margin: 0px 0px 0px 5px;
        }
      }
      p:hover {
        color: #00bbe6;
        cursor: pointer;
      }
    }
  }
}

.photo-container {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Roboto", sans-serif;
  div {
    margin-left: 15%;
    img {
      width: 350px;
      height: 350px;
      border-radius: 50%;
    }
  }
}
