.contact {
  background-image: url("/email-2.jpg");
  height: 80vh;
  font-family: "Roboto", sans-serif;
  background-size: cover;
  color: white;
  h1 {
    padding: 25px;
  }
}

.contact-form {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  div:nth-child(1) {
    width: 30%;
    height: 70%;
    margin-top: 100px;
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
  }
  div:nth-child(2) {
    width: 60%;
    height: 70%;
    margin-top: 110px;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    input {
      width: 40%;
      margin: 5px;
      height: 50px;
      border: none;
      text-indent: 20px;
      background-color: rgba(0, 0, 0, 0);
      border-bottom: 1px solid white;
      color: white;
      font-size: 16px;
      outline: none;
    }
    input[type="submit"]{
        background-color: #1B9CFC;
        border: none;
        transition: 200ms;
        border-radius: 3px;
    }
    input[type="submit"]:hover{
        background-color: #46afff;
        border: none;
        cursor: pointer;
    }
    textarea {
      padding-left: 20px;
      font-size: 16px;
      width: 80%;
      height: 200px;
      background-color: rgba(0, 0, 0, 0);
      border: none;
      color: white;
      border-bottom: 1px solid white;
      resize: none;
      outline: none;
    }
  }
}
::placeholder {
  color: whitesmoke;
}
