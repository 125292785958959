.skills-container {
  font-family: "Roboto", sans-serif;
  background-repeat: no-repeat;
  background-size: contain;
  h1 {
    padding: 25px;
  }
}

.skills-title-1 {
  width: 30%;
  height: 90%;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-size: 22px;
  padding-top: 95px;
  font-weight: 700;  
}
.skills-title-2 {
  width: 30%;
  height: 90%;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-size: 22px;
  padding-top: 45px;
  font-weight: 700;  
}
.technical-container {
  width: 100%;
  display: flex;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-size: 22px;
  font-weight: 700;  
}
.technical {
  width: 70%;
  height: 580px;
  padding-top: 100px;
  margin-bottom: 10px;
  background-color: white;
  display: grid;
  grid-template-columns: auto auto auto auto;
  a {
    width: 150px;
    height: 150px;
    font-size: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    background-color: #f9f9f9;
    transition: 200ms;
  }
  a:hover {
    transform: scale(1.08);
    cursor: pointer;
  }
  a:nth-child(1) {
    color: #25ccf7;
    text-decoration: none;
  }
  a:nth-child(2) {
    img {
      width: 110px;
      height: 100px;
    }
  }
  a:nth-child(3) {
    img {
      width: 110px;
      height: 100px;
    }
  }
  a:nth-child(4) {
    img {
      width: 90px;
      height: 110px;
    }
  }
  a:nth-child(5) {
    color: #f7b731;
    text-decoration: none;
  }
  a:nth-child(6) {
    color: #ee5a24;
    text-decoration: none;
  }
  a:nth-child(7) {
    color: #1e90ff;
    text-decoration: none;
  }
  a:nth-child(8) {
    color: #e84393;
    text-decoration: none;
  }
}
.non-technical {
  height: 780px;
  background-image: url("/team-work.jpg");
  background-size: cover;
  color: white;
}

.filter {
  background-color: rgba(0, 0, 0, 0.5);  
  width: 100%;
  height: 100%;
  display: flex;
}

.non-technical-skill {
  width: 60%;
  color: white;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  padding-top: 50px;
  line-height: 22px;
  p {
    margin-left: 110px;
  }
}
