.about-container {
  background-image: url("/12.jpg");
  height: 500px;
  font-family: "Roboto", sans-serif;
  display: flex;
  align-items: flex-end;
  color: white;
  h1 {
    padding: 25px;
  }
}
.about-left-side {
  width: 30%;
  height: 74%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 22px;
  font-weight: 700;
}

.about-right-side {
  width: 60%;
  height: 74%;
  font-size: 17px;
  font-weight: 100;
  p {
    line-height: 30px;
  }
}
